<script>
import StatusMenu from "@/components/calculation-status-menu.vue";
import FormModal from "@/components/form-modal.vue";
import MasterDetail from "@/components/master-detail.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    FormModal,
    MasterDetail,
    StatusMenu
  },
  computed: {
    ...mapGetters(["clientId"]),
    actionBarButtons() {
      return [
        {
          text: "Recalcular",
          icon: "mdi-cog-sync",
          action: () => {
            this.recalcularDialog = true;
          }
        },
        {
          text: "Cadastro Valor Utilizado",
          icon: "mdi-square-edit-outline",
          action: () => {
            const competencia =
              this.periods.length > 0
                ? this.periods[0]
                : moment().format("yyyy-MM");
            this.createModalData = { competencia };
            this.createModalOpened = true;
          }
        }
      ];
    },
    cols() {
      return this.periods.reduce(
        (acc, key) => [
          ...acc,
          {
            key,
            name: moment(`${key}-01`).format("MM/yyyy"),
            align: 1,
            type: this.$fieldTypes.MONEY,
          }
        ],
        [{ key: "label", name: "Crédito Presumido", sortable: false }]
      );
    },
    customResource() {
      const resource = this.apiResource(
        `v1/faturamento/creditoPresumido/${this.clientId}`
      );
      const self = this;

      return {
        ...resource,
        async get(...args) {
          try {
            const response = await resource.get(...args);
            self.periods = response
              .reduce((acc, { competencia }) => {
                return acc.includes(competencia) ? acc : [...acc, competencia];
              }, [])
              .sort();
            const rows = response.reduce(
              (acc, curr) => {
                const { competencia, ...rest } = curr;
                Object.entries(rest).forEach(([key, value]) => {
                  if (key in acc) {
                    acc[key][competencia] = value;
                  }
                });
                return acc;
              },
              {
                cred_presum_valor: {
                  label: "Calculado"
                },
                cred_presum_utilizado: { label: "Utilizado" }
              }
            );
            self.status = response.reduce(
              (acc, { competencia, calculo }) => ({ ...acc, [competencia]: calculo }),
              { label: null }
            );

            return Object.values(rows);
          } catch (error) {
            self.periods = [];
            return [];
          }
        }
      };
    },
    fields() {
      return [
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          key: "credito_presumido_utilizado",
          name: "Crédito Presumido Utilizado",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
          rules: [{ rule: "required" }]
        }
      ];
    },
    parsedStatus() {
      const getIcon = status => {
        switch (status) {
          case 0:
            return {
              color: "yellow",
              icon: "mdi-alert",
              text: "Aguardando cálculo",
            };
          case 1:
            return {
              color: "green",
              icon: "mdi-check-circle",
              text: "Calculado",
            };
          case 10:
            return {
              color: "orange",
              icon: "mdi-calculator",
              text: "Em processamento",
            };
          case 11:
            return {
              color: "blue",
              icon: "mdi-lock",
              text: "Fechado",
            };
          default:
            return {
              color: "",
              disabled: true,
              icon: "",
              text: "",
            };
        }
      };
      const target = Object.entries(this.status).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: getIcon(value)
        }),
        {}
      );
      const handler = {
        get(target, key) {
          if (key in target) {
            return target[key];
          }

          return getIcon(null);
        }
      };
      return new Proxy(target, handler);
    },
  },
  data: function() {
    return {
      competenciaRecalculo: this.$store.getters.intervaloCompetencia,
      createModalData: {},
      createModalError: "",
      createModalOpened: false,
      dateMenu: false,
      periods: [],
      recalcularDialog: false,
      status: [],
    };
  },
  methods: {
    dialogKeyDown({ code }) {
      if (code === "Escape") {
        this.recalcularDialog = false;
      }
    },
    async doSave(data, closeFn) {
      try {
        this.createModalError = "";
        await this.customResource.save(data);

        if (closeFn) {
          closeFn();
        }

        this.$refs.masterDetail?.doLoad();
      } catch (error) {
        this.createModalError = typeof error === 'string' ? [error] : this.errorHandler(error);
      }
    },
    async recalcularPeriodo() {
      try {
        this.createModalError = "";
        const [ini, fim] = this.competenciaRecalculo;
        const query = `competenciaIni=${ini}&competenciaFim=${fim}`;
        const { save } = this.apiResource(
          `v1/faturamento/calculaPresumidoResumo/${this.clientId}?${query}`
        );
        await save();
        this.recalcularDialog = false;
        this.$refs.masterDetail?.doLoad();
      } catch (error) {
        this.createModalError = this.errorHandler(error);
      }
    }
  }
};
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :actionBarButtons="actionBarButtons"
    class="master-detail"
    :cols="cols"
    :customResource="customResource"
    disableContextMenu
    disablePagination
    :hasFilter="false"
    :hasMonthlyFilter="true"
    :canDelete="false"
    :canEdit="false"
    :hasNewButton="false"
  >
    <template #header="{ head }">
      <v-tooltip bottom :disabled="parsedStatus[head.value].disabled">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-icon
              v-if="!parsedStatus[head.value].disabled"
              :color="parsedStatus[head.value].color"
              small
            >
              {{ parsedStatus[head.value].icon }}
            </v-icon>
            {{ head.text }}
          </span>
        </template>
        <span>{{ parsedStatus[head.value].text }}</span>
      </v-tooltip>
    </template>

    <StatusMenu icon="mdi-alpha-c-box-outline" name="Status de Créd. Presumido" type="creditoPresumido" />

    <FormModal
      :cols="fields"
      :errorMessage="createModalError"
      :opened.sync="createModalOpened"
      title="Crédito Presumido Utilizado"
      :value.sync="createModalData"
      @save="doSave"
    />

    <v-dialog
      v-model="recalcularDialog"
      scrollable
      persistent
      max-width="500"
      @keydown="dialogKeyDown($event)"
    >
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-cog-sync</v-icon> Recalcular
          <v-btn
            @click="recalcularDialog = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p><b> Selecione o período para finalização:</b></p>
          <v-menu
            v-model="dateMenu"
            :close-on-click="competenciaRecalculo.length == 2"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-y
            right
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="competenciaRecalculo | toMonthPeriod"
                dense
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                @click:prepend.stop="on.click"
                v-on="on"
                hide-details
                class="mb-n1 mt-0 mr-3"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="competenciaRecalculo"
              range
              type="month"
              @click:month="dateMenu = competenciaRecalculo.length != 2"
              no-title
            ></v-date-picker>
          </v-menu>
          <template v-if="createModalError">
            <v-alert
              v-for="(error, i) of createModalError"
              dense
              :key="i"
              style="font-size: 13px"
              text
              type="error"
            >
              {{ error }}
            </v-alert>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="recalcularDialog = false"
          >
            <v-icon left>mdi-chevron-left</v-icon> Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="recalcularPeriodo()"
          >
            Executar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </MasterDetail>
</template>

<style lang="scss" scoped>
.master-detail::v-deep .v-data-table__wrapper tbody > tr {
  &:hover {
    background-color: inherit !important;
    cursor: auto;
  }
}
</style>
