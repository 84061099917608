var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MasterDetail',{ref:"masterDetail",staticClass:"master-detail",attrs:{"actionBarButtons":_vm.actionBarButtons,"cols":_vm.cols,"customResource":_vm.customResource,"disableContextMenu":"","disablePagination":"","hasFilter":false,"hasMonthlyFilter":true,"canDelete":false,"canEdit":false,"hasNewButton":false},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var head = ref.head;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.parsedStatus[head.value].disabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(!_vm.parsedStatus[head.value].disabled)?_c('v-icon',{attrs:{"color":_vm.parsedStatus[head.value].color,"small":""}},[_vm._v(" "+_vm._s(_vm.parsedStatus[head.value].icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(head.text)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.parsedStatus[head.value].text))])])]}}])},[_c('StatusMenu',{attrs:{"icon":"mdi-alpha-c-box-outline","name":"Status de Créd. Presumido","type":"creditoPresumido"}}),_c('FormModal',{attrs:{"cols":_vm.fields,"errorMessage":_vm.createModalError,"opened":_vm.createModalOpened,"title":"Crédito Presumido Utilizado","value":_vm.createModalData},on:{"update:opened":function($event){_vm.createModalOpened=$event},"update:value":function($event){_vm.createModalData=$event},"save":_vm.doSave}}),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"500"},on:{"keydown":function($event){return _vm.dialogKeyDown($event)}},model:{value:(_vm.recalcularDialog),callback:function ($$v) {_vm.recalcularDialog=$$v},expression:"recalcularDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-1",staticStyle:{"display":"block"}},[_c('v-icon',{staticClass:"mr-3",attrs:{"x-large":""}},[_vm._v("mdi-cog-sync")]),_vm._v(" Recalcular "),_c('v-btn',{staticStyle:{"position":"absolute","right":"5px","top":"5px"},attrs:{"icon":""},on:{"click":function($event){_vm.recalcularDialog = false}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('p',[_c('b',[_vm._v(" Selecione o período para finalização:")])]),_c('v-menu',{attrs:{"close-on-click":_vm.competenciaRecalculo.length == 2,"close-on-content-click":false,"transition":"scroll-y-transition","offset-y":"","right":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-n1 mt-0 mr-3",attrs:{"value":_vm._f("toMonthPeriod")(_vm.competenciaRecalculo),"dense":"","prepend-icon":"mdi-calendar","readonly":"","hide-details":""},on:{"click:prepend":function($event){$event.stopPropagation();return on.click($event)}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"range":"","type":"month","no-title":""},on:{"click:month":function($event){_vm.dateMenu = _vm.competenciaRecalculo.length != 2}},model:{value:(_vm.competenciaRecalculo),callback:function ($$v) {_vm.competenciaRecalculo=$$v},expression:"competenciaRecalculo"}})],1),(_vm.createModalError)?_vm._l((_vm.createModalError),function(error,i){return _c('v-alert',{key:i,staticStyle:{"font-size":"13px"},attrs:{"dense":"","text":"","type":"error"}},[_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2),_c('v-divider'),_c('v-card-actions',{staticClass:"px-6 pb-3 pt-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"pr-5",attrs:{"color":"primary","dark":"","depressed":""},on:{"click":function($event){_vm.recalcularDialog = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(" Cancelar ")],1),_c('v-btn',{staticClass:"px-5 ml-3",attrs:{"color":"primary","dark":"","depressed":""},on:{"click":function($event){return _vm.recalcularPeriodo()}}},[_vm._v(" Executar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }